import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';

Vue.use(Vuex);

// 读取本地存储购物车信息
let addcartlist = localStorage.getItem('Cartlist');
try {
  addcartlist = JSON.parse(addcartlist) || [];
} catch (error) {
  addcartlist = [];
}

const store = new Vuex.Store({
  state: {
    addcartlist //本地储存购物车数据
  },
  mutations: {
    changea(state, playload) {
      state.a = playload;
      console.log('changea', playload);
    },
    // 更新当前本地存储数据
    changeaddcartlist(state, playload) {
      //state.addcartlist获取的是最新的数据
      localStorage.setItem('Cartlist', JSON.stringify(state.addcartlist));
    },

    // 删除
    removeaddcartlist(state, playload) {
      console.log('state', state.addcartlist);
      console.log('playload', playload);

      state.addcartlist = state.addcartlist.filter((item) => item.id !== playload);
      console.log('res', state.addcartlist);
      localStorage.setItem('Cartlist', JSON.stringify(state.addcartlist));
    }
  },
  actions: {},
  modules: {
    user
  }
});

export default store;
