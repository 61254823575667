import Vue from 'vue';

import {
  Tabbar,
  TabbarItem,
  NavBar,
  Cell,
  Form,
  Field,
  Button,
  Image as VanImage,
  Divider,
  Loading,
  Search,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Row,
  Col,
  Sidebar,
  SidebarItem,
  Icon,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Checkbox,
  SwipeCell,
  Card,
  Empty,
  SubmitBar,
  Toast
} from 'vant';

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Divider);
Vue.use(Loading);
Vue.use(Search);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Icon);
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(GoodsActionButton);
Vue.use(Checkbox);
Vue.use(SwipeCell);
Vue.use(Card);
Vue.use(Empty);
Vue.use(SubmitBar);
Vue.use(Toast);
