import request from '@/utils/request';

// 登录
export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  });
}

// 注册
export function reg(data) {
  return request({
    url: '/user/reg',
    method: 'post',
    data
  });
}

// 校验token
export function verifyToken(data) {
  return request({
    url: '/user/verify',
    method: 'post',
    data
  });
}
