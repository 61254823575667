import { login } from '@/api/user';

const state = {
  userInfo: {},
  token: ''
};

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  }
};

const actions = {
  async login({ commit }, userInfo) {
    try {
      const res = await login(userInfo);
      const { data } = res || {};
      const { Authorization } = data || {};
      commit('SET_USERINFO', data);
      commit('SET_TOKEN', Authorization);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(err);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
