import axios from 'axios';
import { Toast } from 'vant';
// export const host = 'http://8.138.152.95';
// export const host = 'http://8.138.152.95:1231';

// export const host = 'http://yongmie.top';
// export const host = 'http://yongmie.top:1231';
// export const host = 'https://yongmie.top';
// export const host = 'https://yongmie.top:1231';//不能访问

// export const host = 'http://www.yongmie.top';
// export const host = 'http://www.yongmie.top:1231';
// export const host = 'https://www.yongmie.top';
// export const host = 'https://www.yongmie.top:1231';//不能访问

// export const host = 'http://web.yongmie.top';
// export const host = 'http://web.yongmie.top:1231';
// export const host = 'https://web.yongmie.top';
// export const host = 'https://web.yongmie.top:1231'; //不能访问

// export const host = 'http://drone.yongmie.top';
// export const host = 'http://drone.yongmie.top:1231';
// export const host = 'https://drone.yongmie.top';
// export const host = 'https://drone.yongmie.top:1231'; //不能访问

// export const host = process.env.NODE_ENV === 'production' ? 'https://yongmie.top' : 'http://localhost:1231';
// export const host = process.env.NODE_ENV === 'production' ? 'http://8.138.152.95' : 'http://localhost:1231';
export const host = process.env.NODE_ENV === 'production' ? 'https://web.yongmie.top' : 'http://localhost:1231';

const instance = axios.create({
  baseURL: '/api'
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    let userInfo = localStorage.getItem('userInfo');
    try {
      userInfo = JSON.parse(userInfo) || {};
    } catch (err) {
      userInfo = {};
    }
    config.headers['Authorization'] = userInfo.Authorization;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    const { data } = response || {};
    return data;
  },
  (error) => {
    Toast('网络错误');
    return Promise.reject(error);
  }
);

export default instance;
