import Vue from 'vue';
import router from './router';
import store from './store/index';
import './useVant';
import App from './App.vue';
import request, { host } from './utils/request';
import '@/assets/scss/common.scss';

Vue.prototype.$request = request;
Vue.prototype.$host = host;

console.log(process.env.NODE_ENV, 'process.env.NODE_ENV');
console.log(host, 'host');

// import Vant from 'vant';
// import 'vant/lib/index.css';
// Vue.use(Vant);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  store
}).$mount('#app');
