<template>
  <div id="app">
    <!-- 显示来着component注册的组件 -->
    <keep-alive include="Home">
      <router-view></router-view>
    </keep-alive>

    <!-- 底部导航 -->
    <!-- v-if="showMenu" 来控制到其他页面是否显示 -->
    <van-tabbar v-if="showMenu" v-model="active">
      <van-tabbar-item v-for="item in menu" :key="item.path" :icon="item.icon" :to="item.path">
        {{ item.text }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showMenu: 'ture',
      active: 0,
      menu: [
        {
          path: '/home',
          text: '首页',
          icon: 'wap-home-o'
        },
        {
          path: '/discover',
          text: '分类目录',
          icon: 'apps-o'
        },
        {
          path: '/cart',
          text: '购物车',
          icon: 'cart-o'
        },
        {
          path: '/mine',
          text: '我的',
          icon: 'user-circle-o'
        }
      ]
    };
  },
  watch: {
    // 监听路由变化并更新active值
    $route(to, from) {
      this.active = this.menu.findIndex((item) => item.path === to.path);
    }
  },
  created() {
    // 在组件创建时初始化active值
    this.active = this.menu.findIndex((item) => item.path === this.$route.path);
  }
};
</script>

